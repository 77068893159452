const colors = {
  green: {
    '100': '#AEE0B4',
    '400': '#5EC16A',
    '500': '#38A15F',
    '600': '#25854F',
  },
  blue: {
    '50': '#F5F5F5',
    '100': '#E1E1E1',
    '200': '#A6A6A6',
    '300': '#797979',
    '400': '#4D4D4D',
    '500': '#202020',
    '600': '#1A1A1A',
    '700': '#0D0D0D',
    '800': '#060606',
    '900': '#000000',
  },
  red: { '500': '#E73E45', '100': '#FFE5E9' },
  orange: { '100': '#FBECD8' },
  gray: {
    '50': '#F5F5F5', // <-
    '100': '#E1E1E1',
    '200': '#C3C3C3',
    '300': '#A5A5A5',
    '400': '#878787',
    '500': '#696969',
    '600': '#545454',
    '700': '#3F3F3F',
    '800': '#2A2A2A',
    '900': '#151515',
  },
  black: '#000000',
  white: '#ffffff',
  blackAlpha: {
    '50': 'RGBA(0, 0, 0, 0.04)',
    '100': 'RGBA(0, 0, 0, 0.06)',
    '200': 'RGBA(0, 0, 0,0.08)',
    '300': 'RGBA(0, 0, 0,0.16)',
    '400': 'RGBA(0, 0, 0,0.24)',
    '500': 'RGBA(0, 0, 0,0.36)',
    '600': 'RGBA(0, 0, 0, 0.48)',
    '700': 'RGBA(0, 0, 0,0.64)',
    '800': 'RGBA(0, 0, 0, 0.80)',
    '900': 'RGBA(0, 0, 0, 0.92)',
  },
  github: '#171923',
  telegram: '#2775CA',
  linkedin: '#1564BA',
  discord: '#9747FF',
  slack: '#1BA27A',
  twitter: '#000000',
  opensea: '#2081E2',
  facebook: '#4460A0',
  medium: '#231F20',
  reddit: '#FF4500',
  celo: '#FCFF52',
};

export default colors;
